import * as React from 'react';
import { VerticalLineProps } from '../VerticalLine';

type BaseButtonSkinProps = VerticalLineProps & {
  skinsStyle: Record<string, string>;
};

export const BaseVerticalLine: React.FC<BaseButtonSkinProps> = ({
  skinsStyle,
  id,
  onMouseEnter,
  onMouseLeave,
}) => {
  return (
    <div
      id={id}
      className={skinsStyle.root}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {skinsStyle.line && <div className={skinsStyle.line} />}
    </div>
  );
};
